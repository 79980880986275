import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Loader from './assets/loader.gif';
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getRecordingTime } from "./pages/logic/logic";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { availableLanguages } from "./languages/availablelanguages";
import { getCookie } from "./cookie";
import { allConstants } from "./constants";

const englishAbbr = 'en' //SET ENGLISH ABBREVIATION
const defaultResource = { [englishAbbr]: { translation: allConstants } } //SET DEFAULT LANGUAGE RESOURCE FILE TO ENGLISH
const languages = availableLanguages.map(language => language.key) //GET ALL LANGUAGE KEYS
const resources = languages?.filter((l) => l !== englishAbbr).reduce((acc, lang) => {
	acc[lang] = { translation: require(`./languages/${lang}.json`) };
	return acc;
}, defaultResource); //STORE ALL FILE PATHS
const currentLanguage = getCookie('i18next') || englishAbbr //SET CURRENT LANGUAGE

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.use(HttpApi)
  .init({
		//language data source
    resources: resources,
		debug: true,
		//add supported languages in the array
		supportedLngs: languages,
		//current language
    lng: currentLanguage, // if you're using a language detector, do not define the lng option
		//fallback language
		fallbackLng: currentLanguage,
		// order and from where user language should be detected
		detection: {
			order: ['cookie', 'localStorage'],
			caches: ['cookie', 'localStorage'],
		},
		interpolation: {
			escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		},
		//path from where to load the languages
		backend: {
			loadPath: currentLanguage === englishAbbr ? allConstants : '/languages/{{ns}}.json'
		},
		//whether you need to add a suspense fallback in case your languages did not load yet.
		react: {
			useSuspense: true
		}
  });

const App = React.lazy(() => import("./App"));

//SET USER DETAILS ON DOM LOAD
getRecordingTime()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<I18nextProvider i18n={i18n}>
		<Suspense fallback={
			<div className="grid place-content-center place-items-center min-h-screen">
				<img src={Loader} className="w-5/12" alt='Loading...' />
			</div>
		}>
			<App />
		</Suspense>
	</I18nextProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
