const URLs = {
  //HEADER URLS
  clientExt: 'V10',
  devExt: 'V10T',
  localExt: 'localhost',
  prodExt: 'feedbacksuite',

  //GET
  getUserSettings: '/settings/fetch_user_settings',
  getExerciseSettings: '/settings/fetch_exercise_settings',
  getScriptData: '/user/fetch_scripts',
  getExamList: '/protocols/fetch_exam',
  getExamByTrainingType: '/protocols/fetch_protocols',
  getLibraryData: '/library/fetch_library',
  getProtocolAllExams:'/protocols/fetch_all_exam',
  getSubscriberDetails:'/subscriber/subscribers_details',
  getSubscriptionPlans:'/subscription/active_plans',
  getStaffUserDetails:'/user/staff_user_details',
  logoutStaffUser:'/user/Logout_staff_user',
  getCountryList:'/geographical_location/fetch_geographical_locations',
  getUserTypes:'/subscriber/fetch_user_types',
  fetchAdminUsers: '/user/fetch_staff_users',
  getLatestSubscribers: '/subscriber_listing/latest_subscribers',
  getUsersCount: '/subscriber_listing/users_count',

  //POST
  saveExamName: '/protocols/add_exam',
  saveExerciseAudio: '/protocols/upload_protocol_file',
  saveBackgroundImage: '/settings/upload_bg_image',
  renameLibraryFile:'/library/rename_library',
  saveLibrayFile: '/library/add_library',
  saveNewUserData:'/subscriber/register_user',
  userLogin:'/subscriber/Login',
  userLogout:'/subscriber/subscribers_logout',
  forgotPassword:'/subscriber/reset-password-email',
  validateResetToken:'/subscriber/reset-password-email',
  resetPassword:'/subscriber/reset-password',
  getUserPermissions: '/subscriber/fetch_permissions',
  createCheckoutSession: '/create-checkout-session',
  createCheckoutPaymentLink:'/subscription/checkout_subscription',
  createCheckoutPayPalPaymentLink:'/subscription/create_payment',
  getUserDetails: '/user/specific_staff_user_details',
  staffLogin:'/user/Login_staff_user',
  getSubscribers:'/subscriber_listing/subscriber_listing',
  getArchivedusers:'/user/archive_staff_user',
  getSubscriberProfileDetails: '/subscriber_listing/subscriber_profile',
  getNotificationList: '/notifications/fetch_user_notifications',
  getBackgroundImages: '/settings/fetch_bg_image',
  getFeatureUpgrade: '/subscriber/add-permissions',

  //PUT
  updateUserSettings: '/settings/update_user_setting',
  updateExerciseSettings: '/settings/update_exercise_settings',
  updateScriptData: '/user/update_scripts',
  updateExerciseById: '/user/update_exercise',
  updateUserDetails:'/subscriber/edit_subscriber',
  updateUserPassword:'/subscriber/reset-profile-password',
  updateAdminProfile:'/user/update_staff_profile',
  updateAdminPassword:'/user/reset_staff_profile_password',
  markNotificationAsRead:'/notifications/notification_viewed',

  //DELETE
  deleteExamById: '/protocols/delete_exam',
  deleteLibraryFile: '/library/delete_library',
  archiveAdminUser: '/user/archive_staff_user',

  //COMMON
  saveAndUpdateAdminDetails:'/user/staff_user'
}

export default URLs