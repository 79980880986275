import { allConstants } from "../constants"

export const availableLanguages = [
	{
		key: 'en',
		label: 'English',
	},
	{
		key: 'pt',
		label: 'Portuguese',
	}
]

export const en = JSON.stringify(allConstants) //TO BE EXPORTED AND GIVEN TO ESTILL TEAM FOR TRANSLATION